define("editorium/components/cards/terracycle-widget-card/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 15,
                "column": 6
              }
            },
            "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "copy-card-button", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [13, 33], [13, 38]]]]], [], []]], ["loc", [null, [13, 8], [13, 40]]]], ["inline", "edit-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [14, 34], [14, 38]]]]], [], []]], ["loc", [null, [14, 8], [14, 40]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "terracycleWidgetCard");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "terracycleWidgetCard_reorder");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "terracycleWidgetCard_iframeWrapper");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "terracycleWidgetCard_iframeOverlay");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element8, 'onclick');
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(element8, 3, 3);
          morphs[3] = dom.createMorphAt(element8, 5, 5);
          morphs[4] = dom.createMorphAt(dom.childAt(element8, [7]), 3, 3);
          return morphs;
        },
        statements: [["attribute", "onclick", ["subexpr", "action", ["focus"], [], ["loc", [null, [5, 46], [5, 64]]]]], ["inline", "reorder-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [7, 37], [7, 41]]]]], [], []]], ["loc", [null, [7, 8], [7, 43]]]], ["inline", "remove-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [10, 34], [10, 38]]]]], [], []]], ["loc", [null, [10, 6], [10, 40]]]], ["block", "if", [["get", "isFocused", ["loc", [null, [12, 12], [12, 21]]]]], [], 0, null, ["loc", [null, [12, 6], [15, 13]]]], ["inline", "terracycle-widget", [], ["resourceId", ["subexpr", "@mut", [["get", "props.resourceId", ["loc", [null, [20, 21], [20, 37]]]]], [], []], "locale", ["subexpr", "@mut", [["get", "props.locale", ["loc", [null, [21, 17], [21, 29]]]]], [], []], "version", ["subexpr", "@mut", [["get", "props.version", ["loc", [null, [22, 18], [22, 31]]]]], [], []]], ["loc", [null, [19, 8], [23, 10]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 12
                  },
                  "end": {
                    "line": 52,
                    "column": 12
                  }
                },
                "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element1, 'value');
                morphs[1] = dom.createAttrMorph(element1, 'selected');
                morphs[2] = dom.createMorphAt(element1, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "version.code", ["loc", [null, [51, 30], [51, 42]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "version.code", ["loc", [null, [51, 59], [51, 71]]]], ["get", "content.version", ["loc", [null, [51, 72], [51, 87]]]]], [], ["loc", [null, [51, 54], [51, 89]]]]], ["content", "version.name", ["loc", [null, [51, 90], [51, 106]]]]],
              locals: ["version"],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 12
                  },
                  "end": {
                    "line": 63,
                    "column": 12
                  }
                },
                "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element0, 'value');
                morphs[1] = dom.createAttrMorph(element0, 'selected');
                morphs[2] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "locale.code", ["loc", [null, [62, 30], [62, 41]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "locale.code", ["loc", [null, [62, 58], [62, 69]]]], ["get", "content.locale", ["loc", [null, [62, 70], [62, 84]]]]], [], ["loc", [null, [62, 53], [62, 86]]]]], ["content", "locale.name", ["loc", [null, [62, 87], [62, 102]]]]],
              locals: ["locale"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 4
                },
                "end": {
                  "line": 81,
                  "column": 4
                }
              },
              "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "terracycleWidgetCard_modalBody");
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment(" Resource ID input ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "form-group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "resource-id");
              var el4 = dom.createTextNode("Resource ID:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment(" Version Select ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "form-group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "version-select");
              var el4 = dom.createTextNode("Select Version:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("select");
              dom.setAttribute(el3, "id", "version-select");
              dom.setAttribute(el3, "class", "form-control");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("option");
              dom.setAttribute(el4, "value", "");
              dom.setAttribute(el4, "disabled", "");
              dom.setAttribute(el4, "selected", "");
              var el5 = dom.createTextNode("Select a version");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment(" Locale Select ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "form-group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "locale-select");
              var el4 = dom.createTextNode("Select Locale:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("select");
              dom.setAttribute(el3, "id", "locale-select");
              dom.setAttribute(el3, "class", "form-control");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("option");
              dom.setAttribute(el4, "value", "");
              dom.setAttribute(el4, "disabled", "");
              dom.setAttribute(el4, "selected", "");
              var el5 = dom.createTextNode("Select a locale");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "terracycleWidgetCard_modalActions");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment(" Cancel Button ");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "terracycleWidgetCard_cancelButton btn btn-default");
              var el4 = dom.createTextNode("\n            Cancel\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment(" Save Button ");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "terracycleWidgetCard_submitButton btn btn-primary");
              var el4 = dom.createTextNode("\n            Save\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [7, 3]);
              var element4 = dom.childAt(element2, [11, 3]);
              var element5 = dom.childAt(element2, [13]);
              var element6 = dom.childAt(element5, [3]);
              var element7 = dom.childAt(element5, [7]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [3]), 3, 3);
              morphs[1] = dom.createAttrMorph(element3, 'onchange');
              morphs[2] = dom.createMorphAt(element3, 3, 3);
              morphs[3] = dom.createAttrMorph(element4, 'onchange');
              morphs[4] = dom.createMorphAt(element4, 3, 3);
              morphs[5] = dom.createAttrMorph(element6, 'onclick');
              morphs[6] = dom.createAttrMorph(element7, 'onclick');
              return morphs;
            },
            statements: [["inline", "input", [], ["id", "resource-id", "value", ["subexpr", "@mut", [["get", "content.resourceId", ["loc", [null, [39, 18], [39, 36]]]]], [], []], "placeholder", "198bf8db-1a5c-42f4-9787-536d0f1f9e06", "class", "form-control"], ["loc", [null, [37, 10], [42, 12]]]], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "content.version", ["loc", [null, [48, 82], [48, 97]]]]], [], ["loc", [null, [48, 77], [48, 98]]]]], ["value", "target.value"], ["loc", [null, [48, 68], [48, 121]]]]], ["block", "each", [["get", "versions", ["loc", [null, [50, 20], [50, 28]]]]], [], 0, null, ["loc", [null, [50, 12], [52, 21]]]], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "content.locale", ["loc", [null, [59, 81], [59, 95]]]]], [], ["loc", [null, [59, 76], [59, 96]]]]], ["value", "target.value"], ["loc", [null, [59, 67], [59, 119]]]]], ["block", "each", [["get", "locales", ["loc", [null, [61, 20], [61, 27]]]]], [], 1, null, ["loc", [null, [61, 12], [63, 21]]]], ["attribute", "onclick", ["subexpr", "action", ["doneEditing"], [], ["loc", [null, [69, 26], [69, 50]]]]], ["attribute", "onclick", ["subexpr", "action", ["updateWidgetSettings", ["get", "content.resourceId", ["loc", [null, [75, 58], [75, 76]]]], ["get", "content.locale", ["loc", [null, [75, 77], [75, 91]]]], ["get", "content.version", ["loc", [null, [75, 92], [75, 107]]]]], [], ["loc", [null, [75, 26], [75, 109]]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 82,
                "column": 2
              }
            },
            "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-dialog", [], [], 0, null, ["loc", [null, [31, 4], [81, 21]]]]],
          locals: ["content"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "buffered-proxy", [], ["content", ["subexpr", "@mut", [["get", "props", ["loc", [null, [30, 28], [30, 33]]]]], [], []]], 0, null, ["loc", [null, [30, 2], [82, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 0
          }
        },
        "moduleName": "editorium/components/cards/terracycle-widget-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element9, 'class');
        morphs[2] = dom.createMorphAt(element9, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "add-cards", [], ["index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [1, 18], [1, 23]]]]], [], []]], ["loc", [null, [1, 0], [1, 25]]]], ["attribute", "class", ["concat", ["terracycleWidgetCard_wrapper ", ["subexpr", "if", [["get", "isFocused", ["loc", [null, [3, 46], [3, 55]]]], "_is_focused"], [], ["loc", [null, [3, 41], [3, 71]]]]]]], ["block", "click-outside", [], ["action", ["subexpr", "action", ["blur"], [], ["loc", [null, [4, 26], [4, 41]]]], "except-selector", "#modal-overlays"], 0, null, ["loc", [null, [4, 2], [26, 20]]]], ["block", "if", [["get", "isEditing", ["loc", [null, [29, 6], [29, 15]]]]], [], 1, null, ["loc", [null, [29, 0], [83, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});