define('editorium/services/terracycle-widget-manager', ['exports', 'ember-service', 'rsvp'], function (exports, _emberService, _rsvp) {
  exports['default'] = _emberService['default'].extend({
    loadTerraCycleScript: function loadTerraCycleScript(locale, version) {
      return new _rsvp['default'].Promise(function (resolve, reject) {
        var script = document.createElement('script');

        // Construct the script source URL
        script.src = 'https://tc--us-sb-pr-4159.herokuapp.com/' + locale + '/' + version + '.js';

        script.async = true;

        script.onload = function () {
          resolve();
        };

        script.onerror = function (error) {
          return reject(new Error('Failed to load the TerraCycle script for locale ' + locale + ' and version ' + version + ': ' + error.message));
        };

        document.body.appendChild(script);
      });
    },

    removeScript: function removeScript() {
      if (this.get('scriptInserted')) {
        var script = this.get('currentScript');
        if (script) {
          script.parentNode.removeChild(script);
        }
        this.set('scriptInserted', false);
        this.set('currentScript', null);
      }
    }
  });
});