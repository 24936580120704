define('editorium/components/terracycle-widget/component', ['exports', 'ember', 'ember-component'], function (exports, _ember, _emberComponent) {
  exports['default'] = _emberComponent['default'].extend({
    terracycleWidgetManager: _ember['default'].inject.service(),

    resourceId: null,
    locale: null,
    version: null,

    widgetClass: _ember['default'].computed('version', function () {
      var version = this.get('version');
      if (version === 'sdk_v3') {
        return 'terracycle-widget-v3';
      } else if (version === 'sdk_v2') {
        return 'terracycle-widget-new';
      } else {
        return 'terracycle-widget';
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.scheduleOnce('afterRender', this, this.setupWidget);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      _ember['default'].run.once(this, this.setupWidget);
    },

    setupWidget: function setupWidget() {
      var _this = this;

      var _getProperties = this.getProperties('resourceId', 'locale', 'version');

      var resourceId = _getProperties.resourceId;
      var locale = _getProperties.locale;
      var version = _getProperties.version;

      if (!resourceId || !locale || !version) {
        console.error('setupWidget: Missing required properties.');
        return;
      }

      var scriptPromise = this.get('terracycleWidgetManager').loadTerraCycleScript(locale, version);

      scriptPromise.then(function () {
        // Always initialize the widget, even if the script was already loaded
        _this.initializeWidget();
      })['catch'](function (error) {
        return console.error(error);
      });
    },

    initializeWidget: function initializeWidget() {
      var widgetClass = this.get('widgetClass');
      var widgetElement = this.$('.' + widgetClass)[0]; // Get the DOM element

      if (!widgetElement) {
        console.error('Widget element not found during initialization.');
        return;
      }

      var version = this.get('version'); // Corrected property access

      // Call the appropriate initialization function based on the version
      if (version === 'sdk_v3' && window.terracycleV3 && typeof window.terracycleV3.initWidget === 'function') {
        window.terracycleV3.initWidget(widgetElement);
      } else if (version === 'sdk_v2' && window.terracycleV2 && typeof window.terracycleV2.initWidget === 'function') {
        window.terracycleV2.initWidget(widgetElement);
      } else if (version === 'sdk' && window.terracycleV1 && typeof window.terracycleV1.initWidget === 'function') {
        window.terracycleV1.initWidget(widgetElement);
      } else {
        console.error('Unable to initialize TerraCycle widget after saving.');
      }
    }
  });
});