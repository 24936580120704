define('editorium/components/cards/terracycle-widget-card/component', ['exports', 'ember', 'editorium/components/card/component', 'ember-utils', 'editorium/components/cards/terracycle-widget-card/template'], function (exports, _ember, _editoriumComponentsCardComponent, _emberUtils, _editoriumComponentsCardsTerracycleWidgetCardTemplate) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsTerracycleWidgetCardTemplate['default'],

    terracycleWidgetManager: _ember['default'].inject.service(),

    defaultProps: {
      resourceId: null,
      locale: null,
      version: null
    },

    versions: [{ code: 'sdk_v3', name: 'Version 3 (sdk_v3)' }, { code: 'sdk_v2', name: 'Version 2 (sdk_v2)' }, { code: 'sdk', name: 'Version 1 (sdk)' }],

    locales: [{ code: 'en-AU', name: 'Australia (en-AU)' }, { code: 'zh-CN', name: 'China (zh-CN)' }, { code: 'ja-JP', name: 'Japan (ja-JP)' }, { code: 'ko-KR', name: 'Korea (ko-KR)' }, { code: 'en-NZ', name: 'New Zealand (en-NZ)' }, { code: 'nl-BE', name: 'Belgium (Dutch) (nl-BE)' }, { code: 'fr-BE', name: 'Belgium (French) (fr-BE)' }, { code: 'da-DK', name: 'Denmark (da-DK)' }, { code: 'de-DE', name: 'Germany (de-DE)' }, { code: 'es-ES', name: 'Spain (es-ES)' }, { code: 'fr-FR', name: 'France (fr-FR)' }, { code: 'en-IE', name: 'Ireland (en-IE)' }, { code: 'nl-NL', name: 'Netherlands (nl-NL)' }, { code: 'no-NO', name: 'Norway (no-NO)' }, { code: 'de-AT', name: 'Austria (de-AT)' }, { code: 'fr-CH', name: 'Switzerland (French) (fr-CH)' }, { code: 'de-CH', name: 'Switzerland (German) (de-CH)' }, { code: 'sv-SE', name: 'Sweden (sv-SE)' }, { code: 'en-GB', name: 'United Kingdom (en-GB)' }, { code: 'pt-BR', name: 'Brazil (pt-BR)' }, { code: 'en-CA', name: 'Canada (English) (en-CA)' }, { code: 'fr-CA', name: 'Canada (French) (fr-CA)' }, { code: 'en-US', name: 'United States (en-US)' }],

    actions: {
      updateWidgetSettings: function updateWidgetSettings(resourceId, locale, version) {
        // console.log('updateWidgetSettings called with:', resourceId, locale, version);

        if ((0, _emberUtils.isBlank)(resourceId) || (0, _emberUtils.isBlank)(locale) || (0, _emberUtils.isBlank)(version)) {
          window.alert('Invalid Resource ID, Locale, or Version');
          return;
        }

        this.setProperties({
          'props.resourceId': resourceId,
          'props.locale': locale,
          'props.version': version
        });

        this.setupWidget();

        this.send('doneEditing');
        this.commitStore();
      },

      removeCard: function removeCard() {
        this.cleanupWidget();
        this._super.apply(this, arguments);
      }
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      if ((0, _emberUtils.isBlank)(this.get('props.resourceId')) || (0, _emberUtils.isBlank)(this.get('props.locale')) || (0, _emberUtils.isBlank)(this.get('props.version'))) {
        this.send('removeCard');
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupWidget();
    },

    setupWidget: function setupWidget() {
      var _get = this.get('props');

      var resourceId = _get.resourceId;
      var locale = _get.locale;
      var version = _get.version;

      if ((0, _emberUtils.isBlank)(resourceId) || (0, _emberUtils.isBlank)(locale) || (0, _emberUtils.isBlank)(version)) {
        console.error('setupWidget: Missing required properties.');
        return;
      }

      this.get('terracycleWidgetManager').loadTerraCycleScript(locale, version)['catch'](function (error) {
        return console.error(error);
      });
    },

    cleanupWidget: function cleanupWidget() {
      var terracycleManager = this.get('terracycleWidgetManager');
      if (terracycleManager) {
        var terracycleInstance = null;
        switch (this.get('props.version')) {
          case 'sdk_v3':
            terracycleInstance = window.terracycleV3;
            break;
          case 'sdk_v2':
            terracycleInstance = window.terracycleV2;
            break;
          case 'sdk':
            terracycleInstance = window.terracycleV1;
            break;
        }

        if (terracycleInstance && typeof terracycleInstance.cleanup === 'function') {
          terracycleInstance.cleanup();
        }

        terracycleManager.removeScript();
      }
    }
  });
});